import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from 'react-google-maps';
import { If } from 'components';
import { DEFAULT_GEO, ZOOM } from 'config';
import LocationIcon from '../../images/location-icon.png';
import BlackOwnedLocationIcon from '../../images/BlackOwnedLocationIcon.png';
import UserIcon from '../../images/user-icon.png';
import css from './locationsMap.module.css';


const { lat, long } = DEFAULT_GEO;

const SearchAsMove = ({ handleMoveToggle, searchOnMove }) => (
  <button onClick={handleMoveToggle} className={css.mapButton} type="button">
    <div className={css.mapButtonContents}>
      <span className={css[searchOnMove ? 'checked' : 'unchecked']}>
        <i className="fa fa-check" />
      </span>
      <span>Search as map moves</span>
    </div>
  </button>
);

const calculateDistance = (pointA, pointB) => {
  const lat1 = pointA.latitude;
  const lon1 = pointA.longitude;

  const lat2 = pointB.latitude;
  const lon2 = pointB.longitude;

  const R = 6371e3; // earth radius in meters
  const φ1 = lat1 * (Math.PI / 180);
  const φ2 = lat2 * (Math.PI / 180);
  const Δφ = (lat2 - lat1) * (Math.PI / 180);
  const Δλ = (lon2 - lon1) * (Math.PI / 180);

  const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2))
    + ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance; // in meters
};

const LocationsMap = ({
  locations, center, fetch, userLocation = null, urlCenter = null, userZoom, geoType, tab, ...props
}) => {
  const [openLocation, setOpenLocation] = useState(null);
  const [searchOnMove, setSearchOnMove] = useState(true);
  const [moved, setMoved] = useState(false);
  const [zoom, setZoom] = useState((+userZoom || 14.5));
  const [copied, setCopied] = useState(false);
  const mapRef = useRef(null);
  const mapBounds = useRef({
    active: {},
    previous: {},
  });
  const firstRun = useRef(true);
  const Center = urlCenter ? ({ lat: urlCenter.lat, lng: urlCenter.long }) : (userLocation ? { lat: userLocation.lat, lng: userLocation.long } : { lat, lng: long })
  const [mapCenter, setMapCenter] = useState(Center);

  const onClose = () => setOpenLocation(null);

  useEffect(() => {
    if (openLocation && locations.find((i) => i.id === openLocation)) {
      props.onClickMarker(openLocation);
    }
  }, [openLocation, locations.length]);

  useEffect(() => {
    if (userLocation?.lat && userLocation?.long) {
      setMapCenter({ lat: userLocation.lat, lng: userLocation.long });
      setZoom(14.5);
    }
  }, [userLocation]);

  useEffect(() => {
    const reqBody = tab === 'blackOwned' ?
      {
        limit: 800, centerLat: mapCenter.lat, centerLong: mapCenter.long, distance: 8000, categories: 'blackowner'
      } :
      {
        limit: 800, centerLat: mapCenter.lat, centerLong: mapCenter.long, distance: 8000,
      }
    fetch.current(reqBody);
  }, [tab])


  useEffect(() => {
    if (urlCenter?.lat && urlCenter?.long) {
      setMapCenter({ lat: urlCenter.lat, lng: urlCenter.long });
      if (geoType === "country") {
        setZoom(4);
        return
      }
      if (geoType === "administrative_area_level_1") {
        setZoom(9);
        return
      }
      setZoom(14.5);
    }
  }, [urlCenter, geoType]);

  const handleFetch = async () => {
    const currentZoom = mapRef.current.getZoom();
    if (currentZoom > 8) {
      let reqBody
      if (tab === 'blackOwned') {
        reqBody = ({
          limit: 1500,
          centerLat: (mapRef.current.getCenter().lat()),
          centerLong: (mapRef.current.getCenter().lng()),
          distance: 3000000,
          categories: "blackowner"
        });
      } else {
        reqBody = ({
          limit: 500,
          centerLat: (mapRef.current.getCenter().lat()),
          centerLong: (mapRef.current.getCenter().lng()),
          distance: window.innerWidth <= 450 ? 70000 : getRadius(),
        });
      }

      await fetch.current(reqBody);
      firstRun.current = false;
    } else if (!firstRun.current) {
      if (tab !== 'blackOwned') {
        await fetch.current(({ limit: 500 }));
      }
      firstRun.current = true;
    }
  };

  const getRadius = () => {
    const mapLength = (window.innerWidth + window.innerHeight) / 2;
    const center = { latitude: mapRef.current.getCenter().lat(), longitude: mapRef.current.getCenter().lng() };
    const point = pixelToLatlng(0, mapLength / 14.5);
    const distance = calculateDistance(center, point);
    return distance;
  };


  const pixelToLatlng = (xcoor, ycoor) => {
    const ne = mapRef.current.getBounds().getNorthEast();
    const sw = mapRef.current.getBounds().getSouthWest();
    const projection = mapRef.current.getProjection();
    const topRight = projection.fromLatLngToPoint(ne);
    const bottomLeft = projection.fromLatLngToPoint(sw);
    const scale = 1 << mapRef.current.getZoom();
    const newLatlng = projection.fromPointToLatLng(new window.google.maps.Point(xcoor / scale + bottomLeft.x, ycoor / scale + topRight.y));
    return { latitude: newLatlng.lat(), longitude: newLatlng.lng() };
  };

  const onBoundsChanged = (map) => {
    setMoved(true);
  };

  const handleCenterChange = () => {
    setCopied(false);
    handleFetch();
  };

  const handleMoveToggle = () => {
    setSearchOnMove((x) => !x);
    setMoved(false);
  };

  const generateLink = () => {
    const lat = mapRef.current.getCenter().lat()
    const lng = mapRef.current.getCenter().lng()
    const zoom = mapRef.current.getZoom();
    const link = `https://${window.location.hostname}/search?lat=${lat}&lng=${lng}&zoom=${zoom}`
    const textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove();
    setCopied(true);
  }

  const MapSearchButton = () => <SearchAsMove handleMoveToggle={handleMoveToggle} searchOnMove={searchOnMove} />;

  const MapReFetchButton = () => (
    <>
      {moved ? (
        <button className={css.fetchButton} onClick={handleFetch} type="button">
          <span>Redo Search In Map</span>
        </button>
      ) : (
          <MapSearchButton />
        )}
    </>

  );

  const ShareButton = () => (
    <button className={copied ? css.copied : css.copyButton} onClick={generateLink}> {copied ? 'Link Copied' : 'Share This Page'} </button>
  )

  return (
    <GoogleMap
      defaultCenter={{ lat, lng: long }}
      zoom={zoom}
      center={mapCenter}
      ref={mapRef}
      onIdle={searchOnMove ? handleCenterChange : null}
      onBoundsChanged={onBoundsChanged}
    >
      <MapSearchButton />
      <ShareButton />

      {locations.filter((i) => i.geo && i.geo.coordinates && i.geo.coordinates[0] && i.geo.coordinates[1]).map((location, index) => {
        return tab === 'giftCard' ?
          <LocationMarker
            link={location.giftCard}
            tag='Buy Gift Card'
            key={location.id}
            onClick={() => setOpenLocation(location.id)}
            onClose={onClose}
            isSelectedLocation={openLocation === location.id}
            LocationIcon={LocationIcon}
            {...location}
          /> :
          <LocationMarker
            link={location.orderLink}
            tag='Order Now'
            key={location.id}
            onClick={() => setOpenLocation(location.id)}
            onClose={onClose}
            isSelectedLocation={openLocation === location.id}
            LocationIcon={tab === 'blackOwned' ? BlackOwnedLocationIcon : LocationIcon}
            {...location}
          />
      })}
      {userLocation && <Marker tab={tab} position={{ lat: userLocation.lat, lng: userLocation.long }} icon={{ url: UserIcon }} />}
    </GoogleMap>
  );
};

const LocationMarker = ({
  isSelectedLocation, onClick, id, name, geo, giftCard, address, order, link, tag, LocationIcon
}) => (
    <Marker
      key={id}
      icon={{
        url: LocationIcon,
      }}
      title={name}
      position={{ lat: geo.coordinates[1], lng: geo.coordinates[0] }}
      onClick={onClick}
    >
      <If is={isSelectedLocation}>
        <InfoWindow>
          <div className={css.info}>
            <div className={css.name}>
              <strong>{name}</strong>
            </div>

            <div className={css.address}>
              <span className={css.title}>Address</span>
              <br />
              <span>
                {address}
              </span>
            </div>
            <a href={link} target="_blank">{tag}</a>
          </div>
        </InfoWindow>
      </If>
    </Marker>
  );

export default withGoogleMap(LocationsMap);
