/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import Lbc from '@lunchboxinc/lunchbox-components';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import ReactGA from 'react-ga';
import debounce from 'lodash/debounce';
import { Card, Header, LocationsMap } from 'components';
import { Geo } from 'utils';
import { PLACEHOLDER_TEXT, HEADER_TEXT, HEADER_TEXT_PART2, TYPEFORM, ZOOM } from './config';
import { Restaurants } from './api';
import queryString from 'query-string'

import Misc1 from './images/misc1.png';
import Misc2 from "./images/footerLogo.svg"
import Target from './images/target.svg';
import AddMerchant from "./images/AddMerchant.svg";
import Contact from "./images/Contact.svg";
import 'react-google-places-autocomplete/dist/assets/index.css';
import css from './App.module.css';


const { Grid: { Row, Col } } = Lbc;

const App = () => {
  const [fetchingGeo, setFetchingGeo] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [urlCenter, setUrlCenter] = useState(null);
  const [results, setResults] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [zoom, setZoom] = useState(ZOOM);
  const [geoType, setGeoType] = useState(null);
  const [tab, setTab] = useState('giftCard');
  const history = useHistory()
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);

  const [fetching, setFetching] = useState(true);
  const urlLocation = useLocation();
  const listRef = useRef({});
  const firstRun = useRef(true);
  const firstFetch = useRef(true);

  const getGeo = async () => {
    setFetchingGeo(true);
    try {
      const coords = await Geo.getPosition();
      setUserLocation({
        lat: coords.coords.latitude,
        long: coords.coords.longitude,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingGeo(false);
    }
  };

  useEffect(() => {
    const getTotal = async () => {
      try {
        const { data } = await Restaurants.getTotal();
        setTotal(data.total);
      } catch (err) {
        console.error(err);
        setError(err);
      }
    }
    getTotal();
  }, []);


  useEffect(() => {
    const checkGeo = async () => {
      if (urlLocation?.search && firstRun.current) {
        const values = queryString.parse(urlLocation.search)

        if (values?.lat && values?.lng) {
          const urlLat = parseFloat(values.lat);
          const urlLng = parseFloat(values.lng);
          setUrlCenter({ lat: urlLat, long: urlLng })
        }

        if (values?.zoom) {
          const zoom = parseInt(values.zoom);
          setZoom(zoom);
        }
        firstRun.current = false
        return;
      }

      if (urlLocation?.pathname) {
        const city = urlLocation.pathname.split('/');
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'address': city[1] }, function (results, status) {
          if (status === 'OK') {
            if (results[0]) {
              setUrlCenter({ lat: results[0].geometry.location.lat(), long: results[0].geometry.location.lng() })
              setGeoType(results[0].types[0]);
            } else {
              console.log('please check the address parameters of url')
              history.push('/')
            }
          };
        })
        firstRun.current = false
        return;
      }


      if (!navigator.permissions) return;
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      if (permission.state === 'granted') {
        getGeo();
      }
    };
    checkGeo();
  }, []);




  const fetch = useRef(debounce(async (params) => {
    try {
      const { data } = await Restaurants.list({
        name: params.name,
        lat: params.lat,
        long: params.long,
        skip: params.skip,
        limit: params.limit,
        centerLat: params.centerLat,
        centerLong: params.centerLong,
        distance: params.distance,
        categories: params.categories,
      });
      setSkip(data.skip);
      setLimit(data.limit);
      setTotal(data.total);
      setResults(data.results);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setTimeout(() => {
        setFetching(false);
        firstFetch.current = false;
      }, 100)
    }
  }, 300));


  useEffect(() => {
    setFetching(true);
    if (userLocation?.lat && userLocation?.long) {
      fetch.current({
        skip, limit, centerLat: userLocation.lat, centerLong: userLocation.long, distance: 8000,
      });
    }
  }, [userLocation]);



  useEffect(() => {

  }, [])

  const onTabChange = (tab) => {
    setTab(tab);
  }

  const onClickMarker = (id) => {
    if (listRef.current[id]?.scrollIntoView) {
      listRef.current[id].scrollIntoView({ block: 'start' });
    }
  };

  const onSelect = async (args) => {
    const [placesDetails] = await geocodeByPlaceId(args.place_id);
    const { lat, lng: long } = await getLatLng(placesDetails);
    setUserLocation({
      lat, long,
    });
  };

  const resultsMap = {
    giftCard: results.filter(result => result.giftCard),
    order: results.filter(result => result.orderLink),
    // blackOwned: results.filter(result => result.orderLink),
  }

  return (
    <div className={css['page-wrapper']}>
      <Header />
      <div className={css.container}>
        <Col xs={{ span: '1' }} sm={{ span: '1-2' }} md={{ span: '1-2' }} lg={{ span: '1-3' }}>
          <div className={css.sidebar}>
            <div className={css['inquiry-item']}>
              <div>
                <Row spacing={14} className={css['inquiry-container']} style={{ margin: 0 }}>
                  <div className={css['title-container']}>
                    <div className={css.title}>
                      {HEADER_TEXT}
                      <br />
                      <br />
                      {HEADER_TEXT_PART2}
                      <br />
                      <br />
                      Stay safe &nbsp;
                      <a href="https://medium.com/@nihalmehta/help-main-street-109f3ad1ae6" target="_blank">
                        Team Main Street
                       </a>
                      <div className={css['misc-art2']}>
                        <img className={css['misc-2']} src={Misc2} alt="" />
                      </div>
                    </div>
                    <div className={css['misc-art']}>
                      <img className={css['misc-1']} src={Misc1} alt="" />
                    </div>
                  </div>

                  <div className={css['contact-container']}>
                    <div className={css['more-info']}>
                      <a
                        className={css.connect}
                        href={TYPEFORM}
                        onClick={() => {
                          ReactGA.pageview(TYPEFORM);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={AddMerchant} />
                        <p> Add Business </p>
                      </a>

                      <a className={css.contact} href="mailto:info@helpmainstreet.com">
                        <img src={Contact} />
                        <p> Contact Us </p>
                      </a>
                    </div>
                  </div>

                  <div className={css.tab}>
                    <div className={css['tab-nav']}>
                      {/* <button className={css[`tab-button`]} active={tab === 'blackOwned' ? 1 : 0} onClick={() => onTabChange('blackOwned')}>
                        BLACK-OWNED BUSINESS
                      </button> */}
                      <button className={css[`tab-button`]} active={tab === 'giftCard' ? 1 : 0} onClick={() => onTabChange('giftCard')}>
                        BUY <br />GIFT CARDS
                      </button>
                      <button className={css[`tab-button`]} active={tab === 'order' ? 1 : 0} onClick={() => onTabChange('order')}>
                        ORDER <br />ONLINE
                      </button>
                    </div>
                  </div>
                  <Col className={css['search-container']} xs={{ span: '1' }}>
                    <div className={css['input-container']}>
                      <GooglePlacesAutocomplete
                        onSelect={onSelect}
                        inputClassName={css.input}
                        placeholder={`Search ${total} merchants by city or zip`}

                      />
                      <button type="button" className={`${css['geo-button']} ${fetchingGeo ? css.blink : ''}`} onClick={getGeo}>
                        <img className={css.target} src={Target} height={30} width={30} alt="Get Geo location" />
                      </button>
                    </div>
                    <span className={`${css.underline} ${fetching ? css.blink : ''}`} />
                  </Col>
                </Row>
              </div>
            </div>

            <div className={css.list}>
              {resultsMap[tab].map((item) => (
                <Fragment key={item.id}>
                  <div ref={(node) => { listRef.current[item.id] = node; }} style={{ visibility: 'hidden' }} />
                  <Card {...item} tab={tab} />
                </Fragment>
              ))}
            </div>
          </div>
        </Col>
        <Col xs={{ span: '1', hidden: true }} sm="1-2" md="1-2" lg="2-3">
          <div className={css['map-container']}>
            <LocationsMap
              fetch={fetch}
              geoType={geoType}
              locations={resultsMap[tab]}
              onClickMarker={onClickMarker}
              userLocation={userLocation}
              urlCenter={urlCenter}
              userZoom={zoom}
              tab={tab}
              containerElement={<div style={{ height: '100%' }} />}
              mapElement={<div style={{ height: '100%' }} />}
            />
          </div>
        </Col>
      </div>
    </div>
  );
};

export default App;
